// pricing.ts
// pricing is in dollars

import {
  ADDITIONAL_PROJECTS_SERVICE_NAME,
  ENTERPRISE_SERVICE_NAME,
  FREE_SERVICE_NAME,
  PRO_SERVICE_NAME,
  SERVICE_NAMES,
} from './services.js';

const pricing = {
  // usage-based pricing
  translation_per_token: 0.0005,
  // free plan
  [FREE_SERVICE_NAME]: {
    cost: 0,
    soft_limit: 60000, // tokens
    hard_limit: 100000, // tokens
  },
  // pro (self-serve)
  [PRO_SERVICE_NAME]: {
    MONTH: {
      cost: 20,
      tokens_included: 100000, // tokens
    },
    YEAR: {
      cost: 216,
      tokens_included: 1200000, // tokens
    },
  },
  [ADDITIONAL_PROJECTS_SERVICE_NAME]: {
    MONTH: {
      cost: 10,
    },
    YEAR: {
      cost: 108,
    },
  },
  // global
  global: {
    limit: 1000000, // tokens (1M)
  },
} as const;

// ----- Self-Serve Pricing ----- //

export const additionalProjectsPriceIdProduction = {
  MONTH: 'price_1QPwSRE25aVIOBrclQlgs7sl',
  YEAR: 'price_1QWmMrE25aVIOBrcFgx6jHWR',
};

export const additionalProjectsPriceIdDevelopment = {
  MONTH: 'price_1QPrPuE25aVIOBrcl2HYNcK2',
  YEAR: 'price_1QWmMNE25aVIOBrc52u26gFq',
};

// Stripe products for production env
export const lineItemsProduction: {
  [P in
    | (typeof SERVICE_NAMES)[number]
    | typeof ADDITIONAL_PROJECTS_SERVICE_NAME]: {
    MONTH?: any[];
    YEAR?: any[];
  };
} = {
  [PRO_SERVICE_NAME]: {
    MONTH: [
      {
        price: 'price_1QPwJsE25aVIOBrcVFFlWh4h',
        quantity: 1,
      },
      {
        price: 'price_1QfnwYE25aVIOBrcXohAprZm', // per token metered pricing
      },
    ],
    YEAR: [
      {
        price: 'price_1QXB9zE25aVIOBrcA44tVwXa',
        quantity: 1,
      },
      {
        price: 'price_1Qfo0AE25aVIOBrc6S9WUR72', // per token metered pricing
      },
    ],
  },
  [ADDITIONAL_PROJECTS_SERVICE_NAME]: {
    MONTH: [
      {
        price: additionalProjectsPriceIdProduction.MONTH,
        quantity: 1,
      },
    ],
    YEAR: [
      {
        price: additionalProjectsPriceIdProduction.YEAR,
        quantity: 1,
      },
    ],
  },
};

// Stripe test products for development env
export const lineItemsDevelopment: {
  [P in
    | (typeof SERVICE_NAMES)[number]
    | typeof ADDITIONAL_PROJECTS_SERVICE_NAME]: {
    MONTH?: any[];
    YEAR?: any[];
  };
} = {
  [PRO_SERVICE_NAME]: {
    MONTH: [
      {
        price: 'price_1QPrn5E25aVIOBrcFgNpKsGl',
        quantity: 1,
      },
      {
        price: 'price_1Qfo2uE25aVIOBrcvISjH3pF', // per token metered pricing
      },
    ],
    YEAR: [
      {
        price: 'price_1QPsypE25aVIOBrc5WYErwOA',
        quantity: 1,
      },
      {
        price: 'price_1Qfo3aE25aVIOBrcOFmQ8vjH', // per token metered pricing
      },
    ],
  },
  [ADDITIONAL_PROJECTS_SERVICE_NAME]: {
    MONTH: [
      {
        price: additionalProjectsPriceIdDevelopment.MONTH,
        quantity: 1,
      },
    ],
    YEAR: [
      {
        price: additionalProjectsPriceIdDevelopment.YEAR,
        quantity: 1,
      },
    ],
  },
};

export default pricing;

// ----- Webhook Info ----- //

// Mapping of Stripe product IDs to service names
// Each product ID is linked to exactly one service
export const productIdToServiceNameProduction = {
  prod_RIXOsoCJCCTYoG: PRO_SERVICE_NAME,
  prod_RzEUS3nvdcKV2y: ENTERPRISE_SERVICE_NAME,
};
export const productIdToServiceNameDevelopment = {
  prod_RISJ2h06kZPKAb: PRO_SERVICE_NAME,
  prod_RzCASpCrztm3wD: ENTERPRISE_SERVICE_NAME,
};
